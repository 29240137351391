import React from 'react'
import CassiePDF from '../../components/cassiePDF'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import pdf from '../../images/cassieprogram.pdf'

export default function Program() {
    return (
        <Layout>
        <SEO title="Cassie's Ballad program" />
            <div className="program-page-content">
                <div className="program-wrapper">
                    <div class="wine-buttons">
                        <a href={pdf} id="program-download" class="button button--bigger blackButton--border">Download Show Program</a>
                    </div>
                    <CassiePDF />
                </div>
            </div>
        </Layout>
    )
}